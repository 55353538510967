<template>
  <div>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ modalMode === 'create' ? 'Tạo nhân viên' : 'Thông tin nhân viên' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit, errors }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <form-required
              v-model="modalData.role_code"
              :select-options="roleOptions"
              type="select"
              vid="role_code"
              label="Nhóm người dùng"
              value-field="code"
              text-field="name"
              custom-v-error="Nhóm người dùng là bắt buộc"
              rules="required"
              @update-value="val => modalData.role_code = val"
            />

            <form-required
              v-model="modalData.department_code"
              :select-options="departmentOptions"
              type="select"
              vid="department_code"
              label="Phòng ban"
              value-field="code"
              text-field="name"
              custom-v-error="Phòng ban là bắt buộc"
              rules="required"
              @update-value="val => modalData.department_code = val"
            />

            <form-required
              v-model="modalData.job_title_code"
              :select-options="$userJobTitleOptions"
              type="select"
              vid="job_title_code"
              label="Chức danh"
              text-field="label"
              @update-value="val => modalData.job_title_code = val"
            />

            <form-required
              v-model="modalData.phone"
              vid="phone"
              label="Tên đăng nhập (SĐT)"
              custom-v-error="Tên đăng nhập là bắt buộc"
              rules="required"
              @update-value="val => modalData.phone = val"
            />

            <form-required
              v-if="modalMode === 'create'"
              v-model="modalData.password"
              vid="password"
              label="Mật khẩu"
              rules="required|min:8|has-number|has-special-char|has-regular-char"
              @update-value="val => modalData.password = val"
            />
            <small
              v-if="errors['provider-v-password'] && errors['provider-v-password'][0]"
              class="text-danger"
            >{{ errors['provider-v-password'][0] }}</small>

            <form-required
              v-model="modalData.gender"
              vid="gender"
              label="Danh xưng"
              type="select"
              :select-options="$gender.list"
              :text-field="'label'"
              custom-v-error="Danh xưng là bắt buộc"
              rules="required"
              @update-value="val => modalData.gender = val"
            />

            <form-required
              v-model="modalData.fullname"
              vid="fullname"
              label="Họ và tên"
              custom-v-error="Họ và tên là bắt buộc"
              rules="required"
              @update-value="val => modalData.fullname = val"
            />

            <form-required
              v-model="modalData.email"
              vid="email"
              label="Email"
              @update-value="val => modalData.email = val"
            />

            <b-form-group
              label="Ngày làm việc chính thức"
              label-for="user-join_at"
            >
              <flat-pickr
                id="user-join_at"
                v-model="modalData.join_at"
                class="form-control"
                :config="{ altFormat: 'd/m/Y', altInput: true, mode: 'single', allowInvalidPreload: true }"
              />
            </b-form-group>

            <div>
              <uploads
                id="uploads-avatar"
                ref="uploads-avatar"
                :key="'uploads-avatar-' + refreshUploadFile"
                :options="uploadDropzoneOptions"
                @file-success="handleUploaded"
              />
            </div>

            <div class="d-flex justify-content-between mb-3 mt-2">
              <span class="text-primary">Khóa tài khoản</span>
              <b-form-checkbox
                v-model="modalData.is_locked"
                class="text-warning"
                name="is-vertical-menu-collapsed"
                switch
                inline
              />
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2 justify-content-between flex-wrap">
              <b-button
                v-if="modalMode === 'edit'"
                variant="outline-primary"
                class="mb-2 flex-grow-1 flex-sm-grow-0"
                @click="openModalChangePassword"
              >
                Đổi mật khẩu
              </b-button>

              <div class="d-flex justify-content-end align-items-start flex-grow-1 ml-2">
                <b-button
                  v-if="modalMode === 'create'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  Tạo
                </b-button>

                <b-button
                  v-if="modalMode === 'edit'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  Lưu
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Hủy
                </b-button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <modal-change-password
      id="modal-admin-change-password"
      ref="modal-admin-change-password"
      modal-mode="admin-change-password"
      :user="modalData"
    />
  </div>
</template>

<script>
import {
  BButton, BForm, BSidebar, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { alphaNum, email, required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import FormRequired from '@core/components/form-required/FormRequired.vue'
import ModalChangePassword from '@/views/settings/users/list/ModalChangePassword.vue'
import flatPickr from 'vue-flatpickr-component'
import http from '@/global/http'
import Uploads from '@core/components/uploads/Uploads.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    FormRequired,
    BFormCheckbox,
    ModalChangePassword,
    BFormGroup,
    Uploads,

    // Form Validation
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    modalMode: {
      type: String,
      default: null,
    },
    modalData: {
      type: Object,
      default: null,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    departmentOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      refreshUploadFile: 1,
      uploadDropzoneOptions: {
        uploadMultiple: false,
        maxFiles: 1,
        init() {
          this.hiddenFileInput.removeAttribute('multiple')
        },
      },
    }
  },
  watch: {
    modalData() {
      this.refreshUploadFile += 1
    },
  },
  methods: {
    openModalChangePassword() {
      this.$refs['modal-admin-change-password'].$refs['btn-open-modal-change-password'].click()
    },
    handleUploaded(files = []) {
      const avatar = files[0].thumbnail || ''
      Object.assign(this.modalData, { avatar })
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullname: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      join_at: '',
      country: '',
      contract: '',
    }

    const onSubmit = () => emit('submit', props.modalMode, {
      ...props.modalData,
      join_at: http.getISODate(props.modalData.join_at),
    })

    const userData = ref({
      ...blankUserData.value,
    })

    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetUserData)

    return {
      userData,
      onSubmit,
      refFormObserver,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
